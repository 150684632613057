<template>
	<footer>
		<div class="section-content-wrapper">
			<div class="footer-wrapper-top footer-wrapper-extra">
				<h4 style="width: 416px;    max-width: 100%;">{{content.contact_title}}</h4>
				<div class="footer-text-grid">
					<div class="footer-text-col" v-html="content.contact_text">
					</div>
					<a :if="content.contact_button" :href="content.contact_button ? content.contact_button.url : null" class="btn">
						{{content.contact_button ? content.contact_button.title : 'Kontakta oss'}}
					</a>
				</div>
			</div>
			<div class="footer-wrapper-top">
				<a class="logo" href="">
					<img src="@/assets/images/NWLOGO.svg" alt="" />
				</a>
				<div class="footer-text-grid">
					<div class="footer-text-col">
						<h4>Navigation</h4>
						<a 
							v-for="item in content.internal_footer_links" 
							:key="item.name" :href="item.url"
						>
							<p>{{item.name}}</p>
						</a>
					</div>
					<div class="footer-text-col">
						<h4>Kundservice</h4>
						<a 
							v-for="item in content.customer_service_links" 
							:key="item.name" :href="item.url"
						>
							<p>{{item.name}}</p>
						</a>
					</div>
					<div class="footer-text-col">
						<h4>Följ oss</h4>
						<div class="icon-wrapper">
							<a
								target="_blank"
								class="icon instagram"
								href="https://www.instagram.com/nordicwellness/"
							></a>
							<a
								target="_blank"
								class="icon facebook"
								href="https://www.facebook.com/nordicwellness/"
							></a>
						</div>
					</div>
				</div>
			</div>
			<div class="footer-wrapper-bottom">
				<a 
					v-for="item in content.terms_of_service_links"
					:key="item.name"
					:href="item.url"
				>
					<div>
						<p>{{item.name}}</p>
						<span></span>
					</div>
				</a>
				<div>
					<p>Nordic Padel AB 559213-3747</p>
				</div>
			</div>
		</div>
	</footer>
</template>
<script>
import axios from 'axios'
import { baseUrl } from '@/assets/apiLink.js'
export default {
	name: 'Footer',
	data: function () {
		return {
			baseApiUrl: baseUrl,
			content: {},
		}
	},
	mounted() {
		axios.get(this.baseApiUrl + '/umbraco/api/settings/getsettings').then((response) => {
			this.content = response.data
		})
	},
}
</script>
<style scoped lang="scss">
footer {
	@include default-padding-sides;

	padding-top: 115px !important;
	padding-bottom: 30px !important;
	background-color: #000;
	width: 100%;
	bottom: 0;

	.section-content-wrapper {
		flex-direction: column;
		height: 100%;
	}

	hr {
		color: #707070;
	}

	.logo {
		min-width: 215px;
		max-width: 215px;
	}

	.footer-text-grid {
		display: grid;
		grid-template-columns: 1fr 1fr auto;
		color: #fff;
		margin-left: auto;

		.footer-text-col:last-child {
			display: flex;
			flex-direction: column;

			.icon-wrapper {
				display: flex;
			}
		}
		.btn {
			padding: 11px 35px;
			border-radius: 25px;
			border: solid 2px #fff;
			/* line-height: 34px; */
			/* margin-top: 80px; */
			width: 180px;
			font-size: 14px;
			text-transform: uppercase;
		}
		h4 {
			font-family: $secondary-font-sbold;
		}

		.footer-text-col {
			margin-right: 110px;
			h4 {
				margin-bottom: 15px;
				min-width: max-content;
			}
			p {
				margin-bottom: 11px;
				min-width: max-content;
			}
			a {
				@include paragraph-small;
				color: #fff;
				text-decoration: none;
			}
		}
		.footer-text-col:first-child {
			margin-bottom: 20px;
			p {
				margin-bottom: 20px;
			}
		}
		.footer-text-col:last-child {
			margin-right: 0;
		}

		.icon {
			height: 24px;
			width: 24px;
		}

		.instagram {
			background-image: url('../assets/images/instagram.svg');
			margin-right: 25px;
		}

		.facebook {
			background-image: url('../assets/images/facebook.svg');
			height: 24px;
			width: 13px;
			margin-right: 25px;
		}

		.spotify {
			background-image: url('../assets/images/spotify.svg');
		}
	}

	.footer-wrapper-top {
		display: flex;
		justify-content: space-between;
		width: 100%;
		height: 100%;
		padding-bottom: 80px;
		padding-top: 120px;
		@include max-width(small) {
			padding-bottom: 40px;
		}
	}
	.footer-wrapper-extra {
		border-bottom: 1px solid rgba(112, 112, 112, 0.5);
		padding-top: 0;
		max-width: 100%;
		overflow: hidden;
		* {
			color: white;
		}
		h4 {
			font-size: 58px;
			line-height: 1.21;
			@include max-width(medium) {
				font-size: 35px;
				line-height: 1.21;
			}
		}
		.footer-text-grid {
			width: 513px;
			grid-template-columns: auto;
			.footer-text-col {
				p {
					min-width: auto;
				}
			}
		}
	}
	.footer-wrapper-bottom {
		display: flex;
		border-top: 1px solid rgba(112, 112, 112, 0.5);
		padding-top: 30px;

		& > div:last-child {
			margin-left: auto;
			margin-right: 20px;
			p {
				color: #fff;
				opacity: 0.6;
			}
			@include max-width(small) {
				margin: unset;
			}
		}

		div,
		a {
			display: flex;
			align-items: center;
		}

		p {
			color: #fff;
		}

		p:last-child {
			margin-right: 0;
		}

		@include max-width(small) {
			flex-direction: column;
			div {
				margin-bottom: 20px;
			}
		}

		span {
			margin-right: 50px;
			height: 20px;
			width: 20px;
			margin-left: 10px;
			background-image: url('../assets/images/Pil.svg');
		}
	}

	@media (max-width: 900px) {
		height: auto;
		padding-top: 60px !important;
		.footer-wrapper-top {
			display: flex;
			flex-direction: column;

			.footer-text-grid {
				margin-top: 52px;
				margin-left: 0;
				display: flex;
				flex-direction: column;
			}
			.footer-text-col:first-child {
				margin-top: 0;
			}
			.footer-text-col:nth-of-type(2) {
				margin-top: 16px;
			}
			.footer-text-col:nth-of-type(3) {
				margin-top: 22px;
			}
		}
	}

	/* @media (max-width: 600px) {
		height: auto;
		padding-top: 60px !important;
		.footer-text-col:nth-of-type(3) {
			margin-top: 40px;
		}
	} */

	@media (max-width: 430px) {
		.footer-text-grid {
			flex-direction: column;
			margin-top: 25px !important;

			.footer-text-col {
				margin-top: 16px;

				h4 {
					margin-bottom: 11px;
				}
			}

			.footer-text-col:nth-of-type(1) {
				margin-top: 40px;
			}
			.footer-text-col:nth-of-type(3) {
				margin-top: 25px;
			}
		}
	}
}
</style>
